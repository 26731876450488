<template>
  <div>
    <div class="text-right">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="xumed_grey" text x-small v-bind="attrs" v-on="on">
            <v-icon color="xumed_grey" size="20px"
              >mdi-information-slab-circle</v-icon
            >
          </v-btn>
        </template>
        <span class="caption"
          >Distribución de números de pacientes atentidos por sexo
          biológico</span
        >
      </v-tooltip>
    </div>
    <apexchart
      type="bar"
      width="100%"
      :options="chartOptions"
      :series="[
        {
          data: [data.byMale || 0, data.byFemale || 0],
        },
      ]"
    ></apexchart>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      chartOptions: {
        grid: {
          show: false, // Hides both vertical and horizontal grid lines
        },
        tooltip: {
          enabled: false, // Disable tooltips
        },
        chart: {
          type: "bar",
          events: {
            click: function (chart, w, e) {},
          },
          toolbar: {
            show: false,
          },
        },
        colors: [this.$vuetify.theme.currentTheme.primary, "#D81B60"],
        plotOptions: {
          bar: {
            columnWidth: "50%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          dashArray: 4,
        },
        toolbar: {
          show: false, // Disable the toolbar
        },
        xaxis: {
          categories: [["Masculino"], ["Femenino"]],
          labels: {
            style: {
              colors: [this.$vuetify.theme.currentTheme.primary, "#D81B60"],
              fontWeight: 800,
              fontSize: "13px",
            },
          },
        },
        yaxis: {
          categories: [["Masculino"], ["Femenino"]],

          labels: {
            style: {
              colors: [this.$vuetify.theme.currentTheme.xumed_grey],
              fontWeight: 400,
              fontSize: "13px",
            },
            formatter: function (value) {
              return value;
            },
          },
        },
      },
    };
  },
};
</script>
