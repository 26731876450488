<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Reportes</h2>
        <v-divider class="mt-2"></v-divider>
        <div class="mb-8 d-flex">
          <v-tabs
            v-model="tab"
            icons-and-text
            height="40"
            color="secondary"
            slider-color="secondary"
          >
            <v-tab v-for="(item, index) in tabs" :key="index">
              <div class="caption font-weight-medium d-flex align-center">
                <div>{{ item.label }}</div>
              </div>
            </v-tab>
          </v-tabs>
        </div>
        <div class="d-md-flex align-start justify-space-between">
          <div class="d-md-flex align-center">
            <div class="mr-3">
              <RangeDateFilter
                @filter="handleFilterByDate"
                label="Fecha"
                labelRange="Fecha"
              />
            </div>
            <div class="d-flex align-center mb-6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    icon
                    title="Refrescar datos"
                    @click="refreshData"
                    :disabled="disableRefresh"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span class="caption">Refrescar datos</span>
              </v-tooltip>
            </div>
          </div>
        </div>

        <AppointmentsReport ref="AppointmentsReportRef" v-show="tab === 0" class="mt-3" />
        <FinanceReport
          ref="FinanceReportRef"
          v-show="tab === 1"
          class="mt-3"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
import RangeDateFilter from "../components/RangeDateFilter.vue";
import AppointmentsReport from "../components/Appointment/AppointmentsReport.vue";
import FinanceReport from "../components/Finance/FinanceReport.vue";

export default {
  name: "Report",
  components: {
    RangeDateFilter,
    AppointmentsReport,
    FinanceReport,
  },
  data() {
    return {
      todayDate: "",
      from: "",
      to: "",
      terms: "",
      tab: 0,
      tabs: [
        {
          value: 0,
          label: "Consultas",
          dataFetched: false,
        },
        {
          value: 1,
          label: "Finanzas",
          dataFetched: false,
        },
      ],
      disableRefresh: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentLocation"]),
  },
  methods: {
    ...mapMutations(["setAppointments"]),
    refreshData() {
      this.disableRefresh = true;
      setTimeout(() => {
        this.disableRefresh = false;
      }, 2500);

      this.filterApplied();
      this.getData();
    },
    handleFilterByDate({ from, to }) {
      this.from = from || this.todayDate;
      this.to = to || this.todayDate;
      this.getData();
      this.filterApplied();
    },
    getData() {
      this.dataFetched();

      const locationId =
        this.currentLocation?.id && this.currentLocation?.id !== -1
          ? this.currentLocation?.id
          : null;

      if (this.tab === 0) {
        this.$refs.AppointmentsReportRef.handleFilterByDate({
          from: this.from,
          to: this.to,
          locationId,
        });
      }

      if (this.tab === 1) {
        this.$refs.FinanceReportRef.handleFilterByDate({
          from: this.from,
          to: this.to,
          locationId,
        });
      }
    },
    filterApplied() {
      this.tabs = this.tabs.map((tab) => ({ ...tab, dataFetched: false }));
    },
    dataFetched() {
      this.tabs = this.tabs.map((tab) => {
        if (this.tab === tab.value) {
          return {
            ...tab,
            dataFetched: true,
          };
        }

        return tab;
      });
    },
  },
  watch: {
    tab: function (value) {
      const tab = this.tabs.filter((tab) => tab.value === value)[0];
      if (!tab.dataFetched) {
        this.getData();
      }
    },
    currentLocation: function () {
      this.getData();
    },
  },
  mounted() {
    this.todayDate = moment().startOf("day").toDate().toJSON().slice(0, 10);
    this.from = this.todayDate;
    this.to = this.todayDate;
    this.getData();
  },
};
</script>
