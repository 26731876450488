<template>
  <div>
    <apexchart
      type="bar"
      width="100%"
      height="270"
      :options="chartOptions"
      :series="[
        {
          name: 'Importe',
          data: [data.cashSum || 0, data.creditCardSum || 0, data.bankSum || 0],
        },
      ]"
    ></apexchart>
  </div>
</template>

<script>
import { formatCurrency } from "../../helpers/formatters";

export default {
  props: ["data"],
  data() {
    return {
      chartOptions: {
        grid: {
          show: false,
        },
        tooltip: {
          theme: true,
          y: {
            formatter: function (value) {
              return formatCurrency(value);
            },
          },
        },
        chart: {
          type: "bar",
          events: {
            click: function (chart, w, e) {},
          },
          toolbar: {
            show: false,
          },
        },
        colors: [
          this.$vuetify.theme.currentTheme.success,
          this.$vuetify.theme.currentTheme.success,
          this.$vuetify.theme.currentTheme.success,
        ],
        plotOptions: {
          bar: {
            columnWidth: "50%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          dashArray: 4,
        },
        toolbar: {
          show: false, // Disable the toolbar
        },
        xaxis: {
          categories: [["Efectivo"], ["Tarjeta"], ["Transferencia"]],
          labels: {
            style: {
              colors: [
                this.$vuetify.theme.currentTheme.pasive,
                this.$vuetify.theme.currentTheme.pasive,
                this.$vuetify.theme.currentTheme.pasive,
              ],
              fontWeight: 800,
              fontSize: "13px",
            },
          },
        },
        yaxis: {
          categories: [["Efectivo"], ["Tarjeta"], ["Transferencia"]],
          labels: {
            formatter: function (val) {
              return formatCurrency(val);
            },
            style: {
              colors: [this.$vuetify.theme.currentTheme.xumed_grey],
              fontSize: "11px",
            },
          },
        },
      },
    };
  },
};
</script>
