<template>
  <div>
    <div v-if="loadingStats" class="d-flex justify-center my-10">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-slide-x-reverse-transition mode="out-in">
      <div v-if="!loadingStats">
        <v-row>
          <v-col :md="currentUser.doctor.subscription !== 'standard' ? 4 : 12" cols="12">
            <v-card
              rounded="lg"
              class="pa-5"
              :elevation="'6'"
              style="
                height: 100%
                  '; background: linear-gradient(to right, #1d7def, #2eb6b2);';
              "
              dark
            >
              <InfoItem
                label="Ingresos"
                :icon="'mdi-cash-plus'"
                :iconBg="'white'"
                :iconColor="'primary'"
                :labelColor="'white'"
                :valueColor="'white'"
                :important="true"
                :val="formatMoney(stats.payments.totalSum)"
              />
            </v-card>
          </v-col>
          <v-col v-if="currentUser.doctor.subscription !== 'standard'" md="4" cols="12">
            <v-card
              rounded="lg"
              class="pa-5"
              :elevation="'6'"
              style="height: 100%"
            >
              <InfoItem
                label="Gastos"
                :icon="'mdi-currency-usd-off'"
                :iconBg="'primary'"
                :iconColor="'white'"
                :val="formatMoney(stats.expenses.totalSum)"
              />
            </v-card>
          </v-col>
          <v-col v-if="currentUser.doctor.subscription !== 'standard'" md="4" cols="12">
            <v-card
              rounded="lg"
              class="pa-5"
              :elevation="'6'"
              style="height: 100%"
            >
              <InfoItem
                label="Beneficio"
                :icon="'mdi-currency-usd'"
                :iconBg="'primary'"
                :iconColor="'white'"
                :val="
                  formatMoney(stats.payments.totalSum - stats.expenses.totalSum)
                "
              />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition mode="out-in">
      <div v-if="!loadingDateSeries && !loadingStats && currentUser.doctor.subscription !== 'standard'" class="mt-4 mt-md-0">
        <v-row>
          <v-col md="8" cols="12">
            <v-card
              rounded="lg"
              class="pa-5"
              :elevation="'6'"
              style="height: 100%"
            >
              <PaymentSeriesChart :data="paymentSeries" />
            </v-card>
          </v-col>
          <v-col md="4" cols="12">
            <v-card
              rounded="lg"
              class="pa-5"
              :elevation="'6'"
              style="height: 100%"
            >
              <div class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="xumed_grey"
                      text
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="xumed_grey" size="20px"
                        >mdi-information-slab-circle</v-icon
                      >
                    </v-btn>
                  </template>
                  <span class="caption">Ingresos x método de pago</span>
                </v-tooltip>
              </div>
              <PaymentsBarChart :data="stats.payments" />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition mode="out-in">
      <div v-if="!loadingStats" class="mt-4 mt-md-0">
        <v-row>
          <v-col md="4" cols="12">
            <v-card
              rounded="lg"
              class="pa-5"
              :elevation="'6'"
              style="height: 100%"
            >
              <InfoItem
                label="Facturado"
                :icon="'mdi-file-document-outline'"
                :iconBg="'primary'"
                :iconColor="'white'"
                :val="formatMoney(stats.invoices.totalSum)"
              />
            </v-card>
          </v-col>
          <v-col md="4" cols="12">
            <v-card
              rounded="lg"
              class="pa-5"
              :elevation="'6'"
              style="height: 100%"
            >
              <InfoItem
                label="Pendiente de cobrar"
                :icon="'mdi-file-document-alert-outline'"
                :val="
                  formatMoney(
                    stats.invoices.totalSum - stats.invoices.totalPaidSum
                  )
                "
              />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatCurrency } from "../../helpers/formatters";
import Title from "../Title.vue";
import InfoItem from "../InfoItem.vue";
import PaymentSeriesChart from "../Payment/PaymentSeriesChart.vue";
import PaymentsBarChart from "../Payment/PaymentsBarChart.vue";

export default {
  name: "FinanceReport",
  components: {
    Title,
    InfoItem,
    PaymentsBarChart,
    PaymentSeriesChart,
  },
  data() {
    return {
      locationId: null,
      from: "",
      to: "",
      loadingStats: false,
      loadingDateSeries: false,
      stats: {
        expenses: {
          totalSum: 0,
        },
        payments: {
          bankSum: 0,
          cashSum: 0,
          creditCardSum: 0,
          totalSum: 0,
        },
        invoices: {
          totalSum: 0,
          totalPaidSum: 0,
        },
      },
      paymentSeries: [],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapActions(["fetchFinanceStats", "fetchInvoicePaymentDateSeries"]),
    formatMoney(x) {
      return formatCurrency(x);
    },
    handleFilterByDate({ from, to, locationId }) {
      this.from = from;
      this.to = to;
      this.locationId = locationId;
      this.getData();
    },
    async getData() {
      this.getInvoicePaymentDateSeries();
      this.getStats();
    },
    async getInvoicePaymentDateSeries() {
      this.loadingDateSeries = true;
      const data = await this.fetchInvoicePaymentDateSeries({
        doctorId: this.currentUser.doctor.id,
        dateFrom: this.from,
        dateTo: this.to,
        locationId: this.locationId,
      });
      this.paymentSeries = data?.items || [];
      this.loadingDateSeries = false;
    },
    async getStats() {
      this.loadingStats = true;

      const data = await this.fetchFinanceStats({
        doctorId: this.currentUser.doctor.id,
        dateFrom: this.from,
        dateTo: this.to,
        locationId: this.locationId,
      });

      if (!data) {
        this.loadingStats = false;
        return false;
      }

      const { stats } = data;

      this.stats = {
        expenses: {
          totalSum: Number(stats?.expenses?.totalSum) || 0,
        },
        payments: {
          bankSum: Number(stats?.payments?.bankSum) || 0,
          cashSum: Number(stats?.payments?.cashSum) || 0,
          creditCardSum: Number(stats?.payments?.creditCardSum) || 0,
          totalSum: Number(stats?.payments?.totalSum) || 0,
        },
        invoices: {
          totalSum: Number(stats?.invoices?.totalSum) || 0,
          totalPaidSum: Number(stats?.invoices?.totalPaidSum) || 0,
        },
      };

      this.loadingStats = false;
    },
  },
};
</script>

<style>
.v-card--link:before {
  background: none;
}
</style>
