<template>
  <div>
    <div v-if="loadingStats" class="d-flex justify-center my-10">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-slide-x-reverse-transition mode="out-in">
      <div
        v-if="!loadingStats && currentUser.doctor.subscription !== 'standard'"
      >
        <v-row>
          <v-col md="4" cols="12">
            <v-row no-gutters>
              <v-col>
                <v-card
                  rounded="lg"
                  class="pa-5"
                  :elevation="'6'"
                  style="height: 100%"
                >
                  <InfoItem
                    label="Tiempo en consulta"
                    :icon="'mdi-timer-sand-complete'"
                    :iconBg="'primary'"
                    :iconColor="'white'"
                    :val="appointmentTotalTime"
                  />
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-col>
                <v-card
                  rounded="lg"
                  class="pa-5"
                  :elevation="'6'"
                  style="height: 100%"
                >
                  <AppointmentsCompletedGenderChart
                    :data="appointmentsCompletedStats"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" cols="12">
            <v-card
              rounded="lg"
              class="pa-5"
              :elevation="'6'"
              style="height: 100%"
            >
              <div class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="xumed_grey"
                      text
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="xumed_grey" size="20px"
                        >mdi-information-slab-circle</v-icon
                      >
                    </v-btn>
                  </template>
                  <span class="caption"
                    >Proporción entre pacientes atentidos y pendientes de
                    atender</span
                  >
                </v-tooltip>
              </div>
              <div class="mt-md-6">
                <AppointmentsCompletedChart :data="appointmentStats" />
              </div>
            </v-card>
          </v-col>
          <v-col md="4" cols="12">
            <v-card
              rounded="lg"
              class="pa-5"
              :elevation="'6'"
              style="height: 100%"
            >
              <div class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="xumed_grey"
                      text
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="xumed_grey" size="20px"
                        >mdi-information-slab-circle</v-icon
                      >
                    </v-btn>
                  </template>
                  <span class="caption"
                    >Proporción entre consultas canceladas y total de consultas
                    agendadas</span
                  >
                </v-tooltip>
              </div>
              <div class="mt-md-6">
                <AppointmentsDeletedChart :data="appointmentStats" />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition mode="out-in">
      <div v-if="!loadingStats">
        <v-row ref="tableCounters">
          <v-col cols="12" md="4">
            <v-card
              hover
              rounded="lg"
              class="pa-5"
              :elevation="currentStatus === 'finalizado' ? '8' : ''"
              @click="currentStatus = 'finalizado'"
            >
              <InfoItem
                label="Atendido"
                :icon="'mdi-check-bold'"
                :iconBg="'primary'"
                :iconColor="'white'"
                :important="currentStatus == 'finalizado'"
                :val="appointmentStats.completed"
              />
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              hover
              rounded="lg"
              class="pa-5"
              :elevation="currentStatus === 'en espera' ? '8' : ''"
              @click="currentStatus = 'en espera'"
            >
              <InfoItem
                label="Pendiente de atender"
                :icon="'mdi-account-clock'"
                :iconColor="'secondary'"
                :important="currentStatus == 'en espera'"
                :val="appointmentStats.pending"
              />
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              hover
              rounded="lg"
              class="pa-5"
              :elevation="currentStatus === 'anulada' ? '8' : ''"
              @click="currentStatus = 'anulada'"
            >
              <InfoItem
                label="Cancelado"
                :icon="'mdi-close-thick'"
                :iconBg="'error'"
                :iconColor="'white'"
                :important="currentStatus == 'anulada'"
                :val="appointmentStats.deleted"
              />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-slide-x-reverse-transition>
    <v-row>
      <v-col>
        <v-card class="pa-5" style="border-radius: 8px" elevation="5">
          <AppointmentForm ref="appointmentForm" />
          <h4 class="secondary--text subtitle-1 font-weight-bold">
            <v-icon small color="secondary" class="mb-1">{{
              table.icon
            }}</v-icon>
            {{ table.title }}
          </h4>
          <v-divider class="mb-5 mt-2"></v-divider>
          <div class="d-md-flex">
            <div>
              <SearchInput @search="handleSearchInput" />
            </div>
          </div>
          <AppointmentsList
            ref="appointmentsList"
            :headers="table.headers"
            :totalItems="count"
            :appointments="appointments"
            @paginate="handlePaginateTable"
            @clickEditRow="handleClickEdit"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AppointmentsList from "@/components/Appointment/AppointmentsList";
import AppointmentForm from "@/components/Appointment/AppointmentForm.vue";
import moment from "moment";
import RangeDateFilter from "@/components/RangeDateFilter.vue";
import SearchInput from "@/components/SearchInput.vue";
import Title from "../Title.vue";
import InfoItem from "../InfoItem.vue";
import AppointmentsCompletedChart from "../Appointment/AppointmentsCompletedChart.vue";
import AppointmentsCompletedGenderChart from "../Appointment/AppointmentsCompletedGenderChart.vue";
import AppointmentsDeletedChart from "../Appointment/AppointmentsDeletedChart.vue";
import { getDifferenceInSeconds, formatDuration } from "../../helpers/common";

export default {
  name: "AppointmentsReport",
  components: {
    Title,
    InfoItem,
    AppointmentsCompletedChart,
    AppointmentsCompletedGenderChart,
    AppointmentsDeletedChart,
    AppointmentsList,
    AppointmentForm,
    RangeDateFilter,
    SearchInput,
  },
  data() {
    return {
      locationId: null,
      term: "",
      from: "",
      to: "",
      page: 1,
      pageSize: 15,
      count: 0,
      todayDate: "",
      loadingStats: false,
      appointmentStats: {
        deleted: 0,
        completed: 0,
        pending: 0,
      },
      appointmentsCompletedStats: {
        byMale: 0,
        byFemale: 0,
      },
      times: [],
      datetimes: [],

      currentStatus: "finalizado",
    };
  },
  computed: {
    ...mapGetters(["currentUser", "appointments", "currentLocation"]),
    appointmentTotalTime() {
      let totalDifferenceInSeconds = 0;
      this.times.forEach((time) => {
        totalDifferenceInSeconds += getDifferenceInSeconds(
          time.start,
          time.end
        );
      });
      return formatDuration(totalDifferenceInSeconds);
    },

    table() {
      switch (this.currentStatus) {
        case "finalizado":
          return {
            title: "Atendidos",
            icon: "mdi-check-bold",
            headers: [
              { text: "Fecha", value: "attendingDate" },
              { text: "Sede", value: "location" },
              { text: "Paciente", value: "patient" },
              { text: "Motivo", value: "reason" },
              { text: "Hora estimada", value: "estimatedTime" },
              { text: "Atención", value: "time" },
              { text: "Estado de pago", value: "isPaid", align: "center" },
              { text: "", value: "action", align: "center" },
            ],
          };
        case "en espera":
          return {
            title: "Pendientes de atender",
            icon: "mdi-account-clock",
            headers: [
              { text: "Fecha", value: "date" },
              { text: "Sede", value: "location" },
              { text: "Paciente", value: "patient" },
              { text: "Motivo", value: "reason" },
              { text: "Hora estimada", value: "estimatedTime" },
              { text: "Atención", value: "time" },
              { text: "Estado de pago", value: "isPaid", align: "center" },
              { text: "", value: "action", align: "center" },
            ],
          };
        case "anulada":
          return {
            title: "Cancelados",
            icon: "mdi-close-thick",
            headers: [
              { text: "Fecha", value: "date" },
              { text: "Sede", value: "location" },
              { text: "Paciente", value: "patient" },
              { text: "Motivo", value: "reason" },
              { text: "Hora estimada", value: "estimatedTime" },
              { text: "Atención", value: "time" },
              { text: "Estado de pago", value: "isPaid", align: "center" },
              { text: "", value: "action", align: "center" },
            ],
          };
        default:
          return {
            title: "Consultas",
            icon: "",
          };
      }
    },
  },

  methods: {
    ...mapActions(["fetchAppointmentsByDoctor", "fetchAppointmentStats"]),
    ...mapMutations(["setFromDate", "setToDate", "setAppointments"]),
    handleSearchInput(term) {
      this.term = term;
      this.getItemsAndCount();
    },
    handleFilterByDate({ from, to, locationId }) {
      this.from = from;
      this.to = to;
      this.locationId = locationId;
      this.getData();
    },
    handleClickEdit(item) {
      const appointmentByEstimatedTime =
        item.estimatedTime && item.estimatedTime !== "Sin definir";
      this.$refs.appointmentForm.time = appointmentByEstimatedTime
        ? moment(item.estimatedTime, "hh:mm A").format("HH:mm")
        : null;

      this.$refs.appointmentForm.action = "update";
      this.$refs.appointmentForm.form = { ...item };
      this.$refs.appointmentForm.currentDate = item.date;
      this.$refs.appointmentForm.appointmentDialog = true;
    },
    async getData(params) {
      this.getStats();
      this.getItemsAndCount(params);
    },
    async getItemsAndCount(params) {
      this.page = params?.page || 1;
      this.pageSize = params?.pageSize || 15;

      let dateFilter = {
        dateFrom: this.from,
        dateTo: this.to,
      };

      if (this.currentStatus == "finalizado") {
        dateFilter = {
          attendingDateFrom: this.from,
          attendingDateTo: this.to,
        };
      }

      const { count, items } = await this.fetchAppointmentsByDoctor({
        ...dateFilter,
        doctorId: this.currentUser.doctor.id,
        term: this.term,
        page: this.page,
        pageSize: this.pageSize,
        locationId: this.locationId,
        status: this.currentStatus,
        orderBy: [
          {
            column: "appointment.updatedAt",
            direction: "DESC",
          },
        ],
      });

      this.setAppointments(items);
      this.count = count;
    },
    async getStats() {
      this.loadingStats = true;

      const data = await this.fetchAppointmentStats({
        doctorId: this.currentUser.doctor.id,
        dateFrom: this.from,
        dateTo: this.to,
        locationId: this.locationId,
      });

      if (!data) {
        this.loadingStats = false;
        return false;
      }

      const { stats } = data;

      this.appointmentStats = {
        completed: stats.appointmentsCompleted,
        deleted: stats.appointmentsDeleted,
        pending: stats.appointmentsPending,
      };

      this.appointmentsCompletedStats = {
        byMale: stats?.appointmentsCompletedByMale,
        byFemale: stats?.appointmentsCompletedByFemale,
      };
      console.log(
        "stats?.appointmentsCompletedTime",
        stats?.appointmentsCompletedTime
      );
      this.times =
        stats?.appointmentsCompletedTime
          .filter((a) => a.start && a.end)
          .map((t) => ({
            start: t.start,
            end: t.end,
          })) || [];
      console.log("this.times", this.times);
      this.loadingStats = false;
    },
    scrollToTableCounters() {
      const targetElement = this.$refs.tableCounters;

      if (!targetElement) {
        return false;
      }

      const offset = 75; // Offset in pixels

      // Calculate the top position of the target element
      const elementPosition =
        targetElement.getBoundingClientRect().top + window.scrollY;

      // Adjust the position by the offset
      const offsetPosition = elementPosition - offset;

      // Scroll to the adjusted position
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    handlePaginateTable(params) {
      this.getItemsAndCount(params);
      this.scrollToTableCounters();
    },
  },
  watch: {
    currentStatus: function () {
      this.getItemsAndCount();
      this.scrollToTableCounters();
    },
  },
};
</script>

<style>
.v-card--link:before {
  background: none;
}
</style>
