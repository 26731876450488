<template>
  <div>
    <apexchart
      width="100%"
      type="radialBar"
      :options="chartoptions"
      :series="[deletingProportion]"
    ></apexchart>
  </div>
</template>

<script>
import { formatFloat } from "../../helpers/formatters";

export default {
  props: ["data"],
  data() {
    return {
      chartoptions: {
        chart: {
          type: "radialBar",
          offsetY: -10,
        },
        colors: [this.$vuetify.theme.currentTheme.error],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "14px",
                fontWeight: 400,
                color: this.$vuetify.theme.currentTheme.xumed_grey,
                offsetY: 125,
              },
              value: {
                offsetY: 75,
                fontSize: "22px",
                fontWeight: 600,
                color: this.$vuetify.theme.currentTheme.error,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: ["Cancelado"],
      },
    };
  },
  computed: {
    totalAppointments() {
      return (
        Number(this.data?.deleted) +
        Number(this.data?.completed) +
        Number(this.data?.pending)
      );
    },
    deletingProportion() {
      return formatFloat(
        (Number(this.data?.deleted) * 100) / this.totalAppointments
      );
    },
  },
};
</script>
