<template>
  <div>
    <apexchart
      type="radialBar"
      width="100%"
      :options="chartOptions"
      :series="[completedProportion || 0]"
    ></apexchart>
  </div>
</template>

<script>
import { formatFloat } from "../../helpers/formatters";

export default {
  props: ["data"],
  data() {
    return {
      chartOptions: {
        chart: {
          type: "radialBar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              background:
                this.$vuetify.theme.currentTheme.vuetifyMoneyBackground,
              margin: 0,
              size: "70%",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0,
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: this.$vuetify.theme.currentTheme.xumed_grey,
                fontSize: "14px",
                fontWeight: 400,
              },
              value: {
                formatter: function (val) {
                  return parseInt(val) + "%";
                },
                color: this.$vuetify.theme.currentTheme.secondary,
                fontSize: "36px",
                fontWeight: 800,
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [this.$vuetify.theme.currentTheme.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Atendidos"],
      },
    };
  },
  computed: {
    completedProportion() {
      return formatFloat(
        (Number(this.data?.completed) * 100) /
          (Number(this.data?.pending) + Number(this.data?.completed))
      );
    },
  },
};
</script>
